<template>
  <div class="create-content-main">
    <div style="height: 42px; width: 100%; position: relative">
      <div class="col col-auto px-0 div-preview-btn">
        <button
          type="button"
          class="btn btn-preview-fixed btn-primary mr-0"
          v-on:click.prevent="preview()"
        >
          プレビュー
        </button>
      </div>
    </div>
    <div
      class="create-slag-form background-content-admin pt-2 position-relative"
    >
      <!-- <div class="row mx-4 align-items-end">
        <div class="col-md-3 col-sm-12 col-lg-2 pb-2">
          <span
            class="font-title font-weight-bold pl-0 my-auto header-font"
            v-if="!this.$route.params.id"
          >
            単発ページ作成
          </span>
          <span
            class="font-title font-weight-bold pl-0 my-auto header-font"
            v-else
          >
            単発ページ編集
          </span>
        </div>
        <div class="col-md-9 col-sm-12 col-lg-10 d-flex align-items-center">
          <div class="col-sm-9 col-md-8 title-btn mx-3 mx-3 mt-0 col pt-2">
            <label
              v-for="(item, index) in listSetting"
              :key="index"
              class="mr-3 position-relative"
              :class="
                typeSetting === item.value
                  ? 'btn-tab-sale-active'
                  : 'btn-tab-sale-inactive'
              "
              @click="typeSetting = item.value"
            >
              {{ item.text }}
            </label>
          </div>
          <div class="col-sm-3 col-md-4 col-12 px-0">
            <b-button
              variant="primary"
              class="float-md-right btn-preview-fixed"
              v-on:click.prevent="preview()"
            >
              プレビュー
            </b-button>
          </div>
        </div>
      </div> -->
      <div class="create-slag-form">
        <div class="row ml-0 pt-2">
          <h2
            class="col col-auto font-title font-weight-bold cpx-4 my-auto header-font"
            style="margin-right: 10px; bottom: 5px"
            v-if="!this.$route.params.id"
          >
            単発ページ作成
          </h2>
          <h2
            class="col col-auto font-title font-weight-bold mx-3 my-auto header-font"
            style="margin-right: 10px; bottom: 5px"
            v-else
          >
            単発ページ編集
          </h2>
          <div class="col col-auto">
            <div class="mx-3">
              <label
                v-for="(item, index) in listSetting"
                :key="index"
                class="mr-3 position-relative py-2"
                :class="
                  typeSetting === item.value
                    ? 'btn-tab-sale-active'
                    : 'btn-tab-sale-inactive'
                "
                @click="typeSetting = item.value"
                style="font-weight: normal !important; font-size: 17px"
              >
                {{ item.text }}
              </label>
            </div>
          </div>
          <!--<div class="col col-auto px-0 div-preview-btn">
            <b-button
              variant="primary"
              class="float-right btn-preview-fixed"
              style="margin-right: 40px"
              v-on:click.prevent="preview()"
            >
              プレビュー
            </b-button>
          </div>-->
        </div>
      </div>
      <form class="pt-0" id="create-content" autocomplete="off">
        <div v-if="typeSetting === 1">
          <!-- <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">NO</label>
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                disabled
                v-model="infoNew.no"
              />
            </div>
          </div> -->
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >ページタイトル<span class="required">(必須)</span></label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="infoNew.title"
                placeholder="ページタイトル"
              />
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >ページ選択<span class="required">(必須)</span></label
            >
            <!-- <div class="w-100 cp-4 col-12">
              <b-form-select
                class="custome-text-select col-md-6"
                id="select-cate"
                v-model="infoNew.type"
                :options="listCategoryOptions"
              ></b-form-select>
            </div> -->
            <div class="cp-4 col-12">
              <div class="btn-toggle-active">
                <input
                  v-model="infoNew.type"
                  class="m-0 d-none"
                  type="radio"
                  name="radio-type-fixedpage"
                  value="5"
                  id="radio-type-fixedpage"
                />
                <label
                  for="radio-type-fixedpage"
                  class="btn-share-freesize-active py-2 px-4"
                  >1カラムページ</label
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="row row-input"
          :style="{ display: typeSetting === 1 ? '' : 'none' }"
        >
          <label class="col-sm-12 font-weight-bold cp-label mb-0">
            ページ本文
          </label>
          <div
            v-for="(value, index) in infoNew.description"
            :key="index"
            class="w-100"
            style="padding: 0 40px; margin: 20px 0px"
          >
            <div class="mb-2">
              <!-- <input
                  class="form-input w-25 float-right"
                  v-model="value.input"
                  placeholder="表示条件設定"
                /> -->
              <div
                class="col-12 mx-auto justify-content-center p-0 setting-slag setting-slag-fixed-page"
              >
                <b-icon
                  icon="gear-fill"
                  class="my-auto"
                  font-scale="2.5"
                  style="border-color: #ced2d8; padding: 4px"
                  v-on:click="showSlagConditionDetail(value, index)"
                />
                <!-- <div class="row">
                  <div
                    class="setting-show-slag d-flex justify-content-center col-md-6 col-12 pl-md-0 mb-2 px-0 pr-md-4"
                  >
                    <p class="my-auto mr-3">表示設定</p>
                    <div class="custom-select-2 custome-multi-slag">
                      <multiselect
                        v-model="value.slag_display"
                        tag-placeholder="スラグを追加"
                        placeholder=""
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                            :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @remove="changeStatusEdit"
                      >
                        <template slot="noOptions"> リストは空です </template>
                      </multiselect>
                    </div>
                  </div>
                  <div
                    class="setting-hidden-slag d-flex justify-content-center col-md-6 col-12 pr-md-0 mb-2 px-0"
                  >
                    <p class="my-auto mr-3">非表示設定</p>
                    <div class="custom-select-2 custome-multi-slag">
                      <multiselect
                        v-model="value.slag_non_display"
                        tag-placeholder="スラグを追加"
                        placeholder=""
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                            :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @remove="changeStatusEdit"
                      >
                        <template slot="noOptions"> リストは空です </template>
                      </multiselect>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div>
              <input
                type="file"
                hidden
                ref="selectFile"
                id="idSelectFile"
                accept=".pdf"
                @change="changePDF($event, index)"
              />
              <!-- <vue-editor
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  @image-removed="handleImageRemove"
                  v-model="value.description"
                  class="mb-8 w-100 mt-2"
                  :class="`editor-${index}`"
                  style="border: 1px solid #ccc; position: relative"
                ></vue-editor>
                <b-spinner
                  v-if="loadingUpload"
                  style="
                    width: 3rem;
                    height: 3rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                  "
                  large
                ></b-spinner> -->
              <div class="position-relative w-100">
                <editor
                  v-if="!isEdit"
                  v-bind:id="`editor-${index}`"
                  @dataEditor="setValue($event, index)"
                  v-bind:isEdit="isEdit"
                  v-bind:dataEditor="
                    value.description ? JSON.stringify(value.description) : ''
                  "
                ></editor>
                <editor
                  v-if="isEdit && index == 0 && checkPreview != 3"
                  v-bind:id="`editor-${index}`"
                  @dataEditor="setValue($event, index)"
                  v-bind:dataEditor="
                    value.description ? JSON.stringify(value.description) : ''
                  "
                  class="mb-4"
                  v-click-outside="changeStatusEdit"
                ></editor>
                <editor
                  v-if="
                    (isEdit && index > 0) ||
                    (isEdit && index == 0 && checkPreview == 3)
                  "
                  v-bind:id="`editor-${index}`"
                  @dataEditor="setValue($event, index)"
                  v-bind:dataEditor="
                    value.description ? JSON.stringify(value.description) : ''
                  "
                  v-bind:isEdit="isEdit"
                  v-click-outside="changeStatusEdit"
                  class="mb-4"
                ></editor>
                <b-spinner
                  v-if="loadingUpload"
                  style="
                    width: 100%;
                    height: 3rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                  "
                  large
                ></b-spinner>
              </div>
            </div>
            <div
              class="row row-input justify-content-center aligns-items-center"
            >
              <b-icon
                icon="plus"
                class="h1 rounded-circle add-new-description mt-3"
                @click="addNewDescription(index)"
                font-scale="3"
                style="color: #fff; background: #333"
              ></b-icon>
            </div>
          </div>
        </div>
        <div>
          <b-modal
            id="modal-detail-condition"
            modal-class="modalCustom"
            style="width: 720px"
            hide-header
          >
            <div class="d-block text-center">
              <!-- <h5>削除しますと復元できませんのでご注意ください</h5> -->
            </div>
            <!-- Content Condition -->
            <div class="px-3 customBackGround">
              <div class="p-2">
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >基本設定(以下のコンテンツを持っている人)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_1"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        @input="changeMutiSelectContentCondition()"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and1(基本設定とand1両方のコンテンツを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_2"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        @input="changeMutiSelectContentCondition()"
                        :disabled="disabledConditionContent2 === true"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and2(基本設定+and1+2の3点のコンテンツを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_3"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :disabled="disabledConditionContent3 === true"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >非表示（以下のコンテンツを持つユーザーは非表示）</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_4"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Slag condition  -->
            <div class="px-3 mt-4 customBackGround">
              <div class="p-2">
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >基本設定(以下のスラグを持っている人)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_1"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @input="changeMutiSelectSlagCondition()"
                      >
                        <template v-slot:no-options>リストは空です</template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and1(基本設定とand1両方のスラグを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_2"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @input="changeMutiSelectSlagCondition()"
                        :disabled="disabledConditionSlag2 === true"
                      >
                        <template v-slot:no-options>リストは空です</template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and2(基本設定+and1+2の3点のスラグを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_3"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        :disabled="disabledConditionSlag3 === true"
                      >
                        <template v-slot:no-options>リストは空です</template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >非表示（以下のスラグを持つユーザーは非表示）</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_4"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                      >
                        <template v-slot:no-options>リストは空です</template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template #modal-footer="{}">
              <div class="">
                <b-button
                  v-on:click.prevent="updateCondition()"
                  variant="info"
                  class="mr-4"
                  >アップデート
                </b-button>
                <b-button
                  variant="secondary back-btn"
                  @click="closeModalCondition()"
                  class="mx-2"
                  >戻る</b-button
                >
              </div>
            </template>
          </b-modal>
        </div>
        <div v-if="typeSetting === 1">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">
              公開ステータス</label
            >
            <div class="w-100 cp-4">
              <b-form-select
                class="col-md-6 custome-text-select"
                id="select-cate"
                v-model="infoNew.is_public"
                :options="optionPublic"
              ></b-form-select>
            </div>
          </div>
        </div>
        <div v-if="typeSetting === 2">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >ヘッダー設定</label
            >
            <!-- <div class="w-100 cp-4 col-12">
              <b-form-select
                class="custome-text-select col-md-6"
                id="select-cate"
                v-model="infoNew.type"
                :options="listCategoryOptions"
              ></b-form-select>
            </div> -->
            <div class="cp-4 col-12">
              <div class="btn-toggle-active">
                <input
                  v-model="infoNew.type_header"
                  class="m-0 d-none"
                  type="radio"
                  name="radio-type-header"
                  :value="type_header_constant['white_page']"
                  id="radio-type-header-0"
                />
                <label
                  for="radio-type-header-0"
                  :class="
                    infoNew.type_header == type_header_constant['white_page']
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  >真っ白ページ</label
                >
              </div>
              <div class="btn-toggle-active">
                <input
                  v-model="infoNew.type_header"
                  class="m-0 d-none"
                  type="radio"
                  name="radio-type-header"
                  :value="type_header_constant['frame']"
                  id="radio-type-header-1"
                />
                <label
                  for="radio-type-header-1"
                  :class="
                    infoNew.type_header == type_header_constant['frame']
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  >枠ありページ</label
                >
              </div>
              <div class="btn-toggle-active">
                <input
                  v-model="infoNew.type_header"
                  class="m-0 d-none"
                  type="radio"
                  name="radio-type-header"
                  :value="type_header_constant['menu_frame']"
                  id="radio-type-header-2"
                />
                <label
                  for="radio-type-header-2"
                  :class="
                    infoNew.type_header == type_header_constant['menu_frame']
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  >メニュー＋枠ありページ</label
                >
              </div>
              <div class="btn-toggle-active">
                <input
                  v-model="infoNew.type_header"
                  class="m-0 d-none"
                  type="radio"
                  name="radio-type-header"
                  :value="type_header_constant['menu_no_frame']"
                  id="radio-type-header-3"
                />
                <label
                  for="radio-type-header-3"
                  :class="
                    infoNew.type_header == type_header_constant['menu_no_frame']
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  >メニュー＋枠なしページ</label
                >
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >スラグ設定</label
            >
            <div
              class="d-flex justify-content-between align-items-center add-slag col-sm-12 cp-4"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="infoNew.slags_id"
                  tag-placeholder="付与スラグを追加"
                  placeholder="付与スラグを追加"
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >ログインなし設定
            </label>
            <div class="cp-4">
              <b-form-checkbox
                size="lg"
                v-model="infoNew.is_login"
                value="1"
                unchecked-value="0"
              >
                ページ閲覧にログインを必要とする
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div v-if="typeSetting === 3">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >アクセス可能期間</label
            >
            <div
              class="fd-admin-listproperty-card-filter filter-date d-flex cp-4"
            >
              <date-picker
                name="date"
                v-model="infoNew.start_access_date"
                :config="optionsDate"
                autocomplete="off"
                class="custome-text-select m-0"
              ></date-picker>
              <span class="my-auto" style="font-size: 23px">~</span>
              <date-picker
                name="date"
                v-model="infoNew.end_access_date"
                :config="optionsDate"
                autocomplete="off"
                class="custome-text-select m-0"
              ></date-picker>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >アクセス時間後の URL リダイレクト</label
            >
            <div class="col-12 cp-4">
              <input
                class="form-input m-0"
                type="text"
                v-model="infoNew.url_access_redirect"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div :style="{ display: typeSetting === 4 ? '' : 'none' }">
          <!-- type 1 column page -->
          <div
            class="setting-type-5"
            :style="{ display: infoNew.type == 5 ? '' : 'none' }"
          >
            <div
              class="row mb-3 row-input align-items-top"
              style="margin-top: 30px"
            >
              <div class="col-sm-3 text-lg-left px-0">
                <label class="cp-label" for="post_banner-image-button">
                  ヘッダー画像
                </label>
              </div>
              <div class="custome-input-one-column">
                <label for="post_banner_header" class="btn btn-up-file mr-2"
                  >写真選択</label
                >
                <label
                  v-if="option1Column.urlHeaderImg"
                  class="btn btn-delete-file"
                  @click="deleteImg(1)"
                  >写真削除</label
                >
                <br />
                <span class="help-block" style="display: block">
                  <img
                    v-if="option1Column.urlHeaderImg"
                    class="preview-img-fixed preview-img-header"
                    id="post_banner-image-preview"
                    :src="option1Column.urlHeaderImg"
                  />
                </span>
                <input
                  type="file"
                  ref="headerFile"
                  name="post_banner_header"
                  id="post_banner_header"
                  class="d-none"
                  accept="image/jpg, image/png, image/jpeg"
                  @change="onChangeHeaderImage($event)"
                />
              </div>
            </div>
            <div class="row mb-3 row-input cp-4">
              <h5 class="pl-0" style="font-size: 1.57em">背景設定</h5>
            </div>
            <div class="row mb-3 row-input">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label" for="post_banner-image-button">
                  背景色
                </label>
              </div>
              <div class="custome-input-one-column">
                <v-input-colorpicker
                  v-model="infoNew.colorBackground"
                  class="input-color color-field position-absolute"
                  style="z-index: -1"
                />
                <input
                  class="input color-field position-relative"
                  style="width: 45%; margin-left: 0px"
                  type="text"
                  v-model="infoNew.colorBackground"
                  @click="changeColor('input-color')"
                  v-bind:style="{ background: this.infoNew.colorBackground }"
                />
                <span>色を選択</span>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0">
                <label class="cp-label" for="post_banner-image-button">
                  背景画像
                </label>
              </div>
              <div class="custome-input-one-column">
                <label for="post_banner_background" class="btn btn-up-file mr-2"
                  >写真選択</label
                >
                <label
                  v-if="option1Column.urlBackground"
                  class="btn btn-delete-file"
                  @click="deleteImg(2)"
                  >写真削除</label
                >
                <br />
                <span class="help-block" style="display: block">
                  <img
                    v-if="option1Column.urlBackground"
                    class="preview-img-fixed preview-img-background"
                    id="post_banner-image-preview"
                    :src="option1Column.urlBackground"
                  />
                </span>
                <input
                  type="file"
                  ref="backgroundFile"
                  name="post_banner_background"
                  id="post_banner_background"
                  class="d-none"
                  accept="image/jpg, image/png, image/jpeg"
                  @change="onChangeBackgroundImage($event)"
                />
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像繰り返し </label>
              </div>
              <div class="custome-input-one-column" style="margin-top: -10px">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.repeatBackground,
                  }"
                  v-for="(item, index) in listRepeat"
                  :key="index"
                  @click="setBackgroundRepeat(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像固定 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.fixedBackground,
                  }"
                  v-for="(item, index) in listFixed"
                  :key="index"
                  @click="setBackgroundFixed(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像の配置 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.placeBackground,
                  }"
                  v-for="(item, index) in listPlace"
                  :key="index"
                  @click="setBackgroundPlace(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input cp-4">
              <h5 class="pl-0" style="font-size: 1.57em">記事背景設定</h5>
            </div>
            <div class="row mb-3 row-input">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label" for="post_banner-image-button">
                  背景色
                </label>
              </div>
              <div class="custome-input-one-column">
                <v-input-colorpicker
                  v-model="infoNew.colorArticle"
                  class="input-color1 color-field position-absolute"
                  style="z-index: -1"
                />
                <input
                  class="input color-field position-relative"
                  style="width: 45%; margin-left: 0px"
                  type="text"
                  v-model="infoNew.colorArticle"
                  @click="changeColor('input-color1')"
                  v-bind:style="{ background: this.infoNew.colorArticle }"
                />
                <span>色を選択</span>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0">
                <label class="cp-label" for="post_banner-image-button">
                  背景画像
                </label>
              </div>
              <div class="custome-input-one-column">
                <label for="post_banner_article" class="btn btn-up-file mr-2"
                  >写真選択</label
                >
                <label
                  v-if="option1Column.urlArticeBackground"
                  class="btn btn-delete-file"
                  @click="deleteImg(3)"
                  >写真削除</label
                >
                <br />
                <span class="help-block" style="display: block">
                  <img
                    v-if="option1Column.urlArticeBackground"
                    class="preview-img-fixed preview-img-artice"
                    id="post_banner-image-preview"
                    :src="option1Column.urlArticeBackground"
                  />
                </span>
                <input
                  type="file"
                  ref="articleFile"
                  name="post_banner_article"
                  id="post_banner_article"
                  class="d-none"
                  accept="image/jpg, image/png, image/jpeg"
                  @change="onChangeArticleImage($event)"
                />
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像繰り返し </label>
              </div>
              <div class="custome-input-one-column" style="margin-top: -10px">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.repeatArticle,
                  }"
                  v-for="(item, index) in listRepeat"
                  :key="index"
                  @click="setArticleRepeat(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像固定 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.fixedArticle,
                  }"
                  v-for="(item, index) in listFixed"
                  :key="index"
                  @click="setArticleFixed(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像の配置 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.placeArticle,
                  }"
                  v-for="(item, index) in listPlace"
                  :key="index"
                  @click="setArticlePlace(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 影の設定 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.shadow,
                  }"
                  v-for="(item, index) in listShadow"
                  :key="index"
                  @click="setArticleShadow(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label" for="post_banner-image-button">
                  影の色
                </label>
              </div>
              <div class="custome-input-one-column">
                <v-input-colorpicker
                  v-model="infoNew.colorShadow"
                  class="input-color2 color-field position-absolute"
                  style="z-index: -1"
                />
                <input
                  class="input color-field position-relative"
                  style="width: 45%; margin-left: 0px"
                  type="text"
                  v-model="infoNew.colorShadow"
                  @click="changeColor('input-color2')"
                  v-bind:style="{ background: this.infoNew.colorShadow }"
                />
                <span>色を選択</span>
              </div>
            </div>
          </div>
          <!-- TYPE INPUT FREE -->
          <div
            class="setting-type-5"
            v-if="infoNew.type == 5 && user_type == typeCheck['cdea']"
          >
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label">追加CSS</label>
              <CTextarea
                v-model="infoNew.css"
                class="w-100 cp-4"
                rows="8"
              ></CTextarea>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >追加Javascript</label
              >
              <CTextarea
                v-model="infoNew.js"
                class="w-100 cp-4"
                rows="8"
              ></CTextarea>
            </div>
          </div>
        </div>
      </form>
      <div class="row justify-content-center">
        <b-button
          v-on:click.prevent="save()"
          :disabled="loadingSave"
          variant="success"
          class="mr-4 btn-color"
        >
          <b-spinner v-if="loadingSave" small></b-spinner>
          編集内容を反映
        </b-button>
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="cancel()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import { VueEditor } from "vue2-editor";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { FeedBack } from "@/utils/feedback.js";
import { Api } from "../../utils/http-common.js";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants.js";
import InputColorPicker from "vue-native-color-picker";
import Editor from "../../components/editor/index.vue";
import { eventBus } from "../../main";
import vClickOutside from "v-click-outside";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "createFixedPage",
  components: {
    // VueEditor,
    Editor,
    "v-input-colorpicker": InputColorPicker,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      listSetting: [
        { value: 1, text: "基本設定" },
        { value: 2, text: "詳細設定" },
        { value: 3, text: "期間設定" },
        { value: 4, text: "デザイン" },
      ],
      typeSetting: 1,
      id: this.$route.params.id ? this.$route.params.id : null,
      feedback: FeedBack,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      listCategoryOptions:
        localStorage.getItem(Constants.USER_TYPE_ADMIN) ==
        Constants.USER_TYPE_DEFAULT["cdea"]
          ? Constants.CATEGORY_FIXED_PAGE_CDEA
          : Constants.CATEGORY_FIXED_PAGE,
      listSlagOptions: [],
      optionPublic: [
        { text: "非公開", value: 0 },
        { text: "公開中", value: 1 },
      ],
      infoNew: {
        no: localStorage.getItem(Constants.NO_FIXED_PAGE),
        title: "",
        type: 5,
        description: [
          {
            description: "",
            content_condition_1: [],
            content_condition_2: [],
            content_condition_3: [],
            content_condition_4: [],
            slag_condition_1: [],
            slag_condition_2: [],
            slag_condition_3: [],
            slag_condition_4: [],
          },
        ],
        type_header: 3,
        slags_id: [],
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        start_access_date: "",
        end_access_date: "",
        url_access_redirect: "",
        is_login: 1,
        is_public: 1,
        colorBackground: "#ffffff",
        repeatBackground: 0,
        fixedBackground: 0,
        placeBackground: 0,
        colorArticle: "#ffffff",
        repeatArticle: 0,
        fixedArticle: 0,
        placeArticle: 0,
        shadow: 0,
        colorShadow: "#000000",
        indBackgroundRepeat: 0,
        imgHeader: "",
        imgBackground: "",
        imgArticle: "",
        css: "",
        js: "",
      },
      optionsDate: {
        format: "YYYY-MM-DD HH:mm:ss",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      isEdit: false,
      loadingSave: false,
      checkAdd: null,
      loadingUpload: false,
      color: "",
      listRepeat: [
        { text: "繰り返す", value: 0 },
        { text: "横方向に繰り返す", value: 1 },
        { text: "縦方向に繰り返す", value: 2 },
        { text: "繰り返さない", value: 3 },
      ],
      listFixed: [
        { text: "固定する", value: 0 },
        { text: "固定しない", value: 1 },
      ],
      listPlace: [
        { text: "右よせ", value: 0 },
        { text: "中央", value: 1 },
        { text: "左よせ", value: 2 },
      ],
      listShadow: [
        { text: "ON", value: 0 },
        { text: "OFF", value: 1 },
      ],
      action: 0,
      check: 0,
      addNew: false,
      checkPreview: 1,
      checkAddEditor: 1,
      ind: null,
      option1Column: {
        urlHeaderImg: "",
        flagLogo: false,
        fileNameHeader: "",
        urlBackground: "",
        flagBackground: false,
        fileNameBackground: "",
        urlArticeBackground: "",
        flagArticle: false,
        fileNameArtice: "",
        checkHeader: "",
      },
      type_header_constant: Constants.HEADER_FIXED_PAGE_TYPE,
      user_type: localStorage.getItem(
        Constants.USER_TYPE_ADMIN +
          (this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN)
      ),
      typeCheck: Constants.USER_TYPE_DEFAULT,
      listContentOptions: [],
      content_condition_1: [],
      content_condition_2: [],
      content_condition_3: [],
      content_condition_4: [],
      slag_condition_1: [],
      slag_condition_2: [],
      slag_condition_3: [],
      slag_condition_4: [],
      disabledConditionContent2: false,
      disabledConditionContent3: false,
      disabledConditionSlag2: false,
      disabledConditionSlag3: false,
    };
  },
  created() {
    // if (
    //   localStorage.getItem(Constants.USER_TYPE_ADMIN) ==
    //   Constants.USER_TYPE_DEFAULT["cdea"]
    // ) {
    //   this.listSetting.push({ value: 4, text: "デザイン" });
    // }
    // const req = {
    //   shop_id: this.shop_id,
    //   type: this.type,
    // };
    // this.getListFixed(req);

    const request = {
      shop_id: this.shop_id,
    };
    this.getALLlistSlag(request);
    const requestContent = {
      page: 1,
      data: {
        shop_id: this.shop_id,
        limit: "all",
      },
    };
    this.getListContents(requestContent);
    const reqData = {
      id: this.$route.params.id,
      shop_id: this.shop_id,
    };
    if (reqData.id) {
      this.isEdit = true;
      this.getFixedPageById(reqData);
    }
    this.infoNew = localStorage.getItem(Constants.PAGE_PREVIEW)
      ? JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW))
      : this.infoNew;
    this.option1Column = localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE)
      ? JSON.parse(localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE))
      : this.option1Column;
    if (localStorage.getItem(Constants.PAGE_PREVIEW)) {
      this.checkPreview = 3;
    }
    if (localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE)) {
      this.option1Column.urlHeaderImg = this.checkHeaderImg;
      this.option1Column.urlBackground = this.checkBackgroundImg;
      this.option1Column.urlArticeBackground = this.checkArticleImg;
    }
  },
  mounted() {
    // let container = document.getElementsByClassName("ql-toolbar")[0];
    // let item = document.createElement("span");
    // item.classList.add("ql-formats");
    // let input = document.createElement("button");
    // input.addEventListener("click", this.toggleModal);
    // input.setAttribute("type", "button");
    // input.classList.add("ql-test");
    // input.innerHTML = "PDF";
    // item.appendChild(input);
    // container.appendChild(item);
  },
  computed: {
    ...mapGetters([
      "listSlag",
      "listContents",
      "detailFixedPage",
      "success",
      "message",
      "error",
      "checkHeaderImg",
      "checkBackgroundImg",
      "checkArticleImg",
      "listFixedPage",
      "isShowLayout",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    listFixedPage() {
      let id = this.$route.params.id ? this.$route.params.id : "";
      if (!id) {
        this.infoNew.no = this.listFixedPage.length + 1;
      }
    },
    detailFixedPage() {
      this.infoNew.title = this.detailFixedPage.title;
      this.infoNew.type = 5;
      this.infoNew.type_header = this.detailFixedPage.type_header;
      this.infoNew.description = JSON.parse(this.detailFixedPage.description);
      this.infoNew.start_access_date = this.detailFixedPage.start_access_date
        ? this.detailFixedPage.start_access_date
        : "";
      this.infoNew.end_access_date = this.detailFixedPage.end_access_date
        ? this.detailFixedPage.end_access_date
        : "";
      this.infoNew.url_access_redirect = this.detailFixedPage
        .url_access_redirect
        ? this.detailFixedPage.url_access_redirect
        : "";
      this.infoNew.is_login = this.detailFixedPage.is_login;
      this.infoNew.is_public = this.detailFixedPage.is_public;
      this.infoNew.slags_id = this.detailFixedPage.slags.map((item) => ({
        slag_id: item.slag_id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
      if (this.infoNew.type == 5) {
        this.infoNew.colorBackground = this.detailFixedPage.background_color;
        this.infoNew.repeatBackground =
          this.detailFixedPage.dulipcate_background;
        this.infoNew.fixedBackground = this.detailFixedPage.fixed_background;
        this.infoNew.placeBackground = this.detailFixedPage.position_background;
        this.infoNew.colorArticle =
          this.detailFixedPage.article_background_color;
        this.infoNew.repeatArticle =
          this.detailFixedPage.article_background_repeat;
        this.infoNew.fixedArticle =
          this.detailFixedPage.article_background_fixed;
        this.infoNew.placeArticle =
          this.detailFixedPage.article_background_position;
        this.infoNew.shadow = this.detailFixedPage.article_background_shadow;
        this.infoNew.colorShadow = this.detailFixedPage.shadow_color;
        this.option1Column.urlHeaderImg = localStorage.getItem(
          Constants.PREVIEW_COLUMN_PAGE
        )
          ? this.checkHeaderImg
          : this.detailFixedPage.logo_image
          ? process.env.VUE_APP_ROOT_BACKEND +
            "/" +
            this.detailFixedPage.logo_image
          : "";
        this.option1Column.urlBackground = localStorage.getItem(
          Constants.PREVIEW_COLUMN_PAGE
        )
          ? this.checkBackgroundImg
          : this.detailFixedPage.background_image
          ? process.env.VUE_APP_ROOT_BACKEND +
            "/" +
            this.detailFixedPage.background_image
          : "";
        this.option1Column.urlArticeBackground = localStorage.getItem(
          Constants.PREVIEW_COLUMN_PAGE
        )
          ? this.checkArticleImg
          : this.detailFixedPage.article_background_image
          ? process.env.VUE_APP_ROOT_BACKEND +
            "/" +
            this.detailFixedPage.article_background_image
          : "";
        this.$store.commit("set", [
          "checkHeaderImg",
          this.option1Column.urlHeaderImg,
        ]);
        this.$store.commit("set", [
          "checkBackgroundImg",
          this.option1Column.urlBackground,
        ]);
        this.$store.commit("set", [
          "checkArticleImg",
          this.option1Column.urlArticeBackground,
        ]);
      }
      this.infoNew.css = this.detailFixedPage.css
        ? this.detailFixedPage.css
        : "";
      this.infoNew.js = this.detailFixedPage.js ? this.detailFixedPage.js : "";
      this.infoNew = localStorage.getItem(Constants.PAGE_PREVIEW)
        ? JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW))
        : this.infoNew;
      if (this.checkAdd != 0) {
        // this.addPdf(this.checkAdd);
      }
      this.changeStatusEdit();
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
    },
    listContents() {
      this.listContentOptions = this.listContents.map((item) => ({
        value: item.id,
        text: item.title,
      }));
    },
  },
  methods: {
    ...mapActions({
      getALLlistSlag: "getALLlistSlag",
      getListContents: "getListContents",
      // getListFixed: "getListFixed",
      CreateFixedPage: "CreateFixedPage",
      getFixedPageById: "getFixedPageById",
      UpdateFixedPage: "UpdateFixedPage",
    }),
    cancel() {
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
      this.$router.push({
        name: this.$route.params.shopId
          ? "listFixedPage"
          : "listFixedPage domain",
      });
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (file.size / 1024 / 1024 > 100) {
        this.$toasted.error("画像1枚につき100MBまで");
        resetUploader();
      } else if (
        file.type != "image/jpg" &&
        file.type != "image/png" &&
        file.type != "image/jpeg"
      ) {
        this.$toasted.error("画像はjpeg、jpg、png形式である必要があります。");
        resetUploader();
      } else {
        var formData = new FormData();
        formData.append("image", file);
        formData.append("id", this.$route.params.id);

        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    toggleModal() {
      document.getElementById("idSelectFile").click();
    },
    async changePDF(e, index) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      if (e.target.files[0].size / 1024 / 1024 > 10) {
        this.$toasted.error(
          "アップロード可能なファイルのサイズは10MBまでです。"
        );
      } else {
        this.loadingUpload = true;
        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            this.infoNew.description[index].description +=
              '<iframe class="ql-pdf" frameborder="0" allowfullscreen="true" src="' +
              url +
              '"></iframe><p><br></p>';
            this.loadingUpload = false;
            eventBus.$emit("getFormEditor");
          })
          .catch(() => {
            this.loadingUpload = false;
          });
      }
    },
    async save() {
      this.loadingSave = true;
      if (
        this.infoNew.start_access_date &&
        this.infoNew.end_access_date &&
        this.infoNew.start_access_date > this.infoNew.end_access_date
      ) {
        this.$toasted.error("終了日には、開始日より後の日付を指定してください");
        this.loadingSave = false;
      } else {
        this.checkAddEditor = 1;
        this.check = 0;
        this.changeStatusEdit();
        eventBus.$emit("getFormEditor");
      }
    },
    preview() {
      // localStorage.setItem(
      //   Constants.PAGE_PREVIEW,
      //   JSON.stringify(this.infoNew)
      // );
      // this.$router.push({
      //   name: "previewPage",
      //   params: { shopId: this.$route.params.shopId },
      // });
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.checkPreview = 2;
      this.checkAddEditor = 1;
      this.check = 0;
      // this.isEdit = undefined;
      eventBus.$emit("getFormEditor");
    },
    addNewDescription(index) {
      // this.isEdit = !this.isEdit;
      this.check = 0;
      this.checkAddEditor = 2;
      this.ind = index;
      this.changeStatusEdit();
      eventBus.$emit("getFormEditor");
    },

    // addPdf(index) {
    //   let container = document.getElementsByClassName("ql-toolbar")[index];
    //   let item = document.createElement("span");
    //   item.classList.add("ql-formats");
    //   let input = document.createElement("button");
    //   input.addEventListener("click", this.toggleModal);
    //   input.setAttribute("type", "button");
    //   input.classList.add("ql-test");
    //   input.innerHTML = "PDF";
    //   item.appendChild(input);
    //   container.appendChild(item);
    // },
    changeColor(name) {
      document.getElementsByClassName(name)[0].click();
    },
    setAction(value, index) {
      this.action = index;
    },
    changeStatusEdit() {
      this.isEdit = false;
    },
    revertFile(oldFile, fileName) {
      var dataurl = oldFile[0].src;
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    },
    async setValue(e, ind) {
      this.infoNew.description[ind].description = e;
      this.check += 1;
      if (this.check == this.infoNew.description.length) {
        let shop_id = this.shop_id;
        let slags = [];
        this.infoNew.slags_id.forEach((value) => {
          slags.push(value.slag_id);
        });
        // if (this.checkPreview != 2 && this.checkAddEditor != 2) {
        //   this.infoNew.description.forEach((value) => {
        // var list_slag_show = value.slag_display;
        // var list_slag_hidden = value.slag_non_display;
        // var slags_show = [];
        // var slags_hidden = [];
        // if (list_slag_show && list_slag_show.length > 0) {
        //   list_slag_show.forEach((slag) => {
        //     slags_show.push(slag.slag_condition);
        //   });
        // }
        // if (list_slag_hidden && list_slag_hidden.length > 0) {
        //   list_slag_hidden.forEach((slag) => {
        //     slags_hidden.push(slag.slag_condition);
        //   });
        // }
        // value.slag_display = slags_show;
        // value.slag_non_display = slags_hidden;
        //   });
        // }
        if (this.infoNew.type == 5) {
          if (this.option1Column.fileNameHeader) {
            // var imgPreView = this.checkImg
            //   ? document.getElementsByClassName("preview-img-header")
            //   : document.getElementsByClassName("img-preview");
            var imgPreViewHeader =
              document.getElementsByClassName("preview-img-header");
            this.infoNew.imgHeader = this.revertFile(
              imgPreViewHeader,
              this.option1Column.fileNameHeader
            );
          }
          if (this.option1Column.fileNameBackground) {
            var imgPreViewBackground = document.getElementsByClassName(
              "preview-img-background"
            );
            this.infoNew.imgBackground = this.revertFile(
              imgPreViewBackground,
              this.option1Column.fileNameBackground
            );
          }
          if (this.option1Column.fileNameArtice) {
            var imgPreViewArtice =
              document.getElementsByClassName("preview-img-artice");
            this.infoNew.imgArticle = this.revertFile(
              imgPreViewArtice,
              this.option1Column.fileNameArtice
            );
          }
        }
        if (this.infoNew.description && this.infoNew.description.length > 0) {
          this.infoNew.description.map((item) => {
            const blocksData = item.description.blocks;
            blocksData.map((itemBlock) => {
              if (itemBlock.type === "tableOfContents") {
                itemBlock.data.items = itemBlock.data.items.map(
                  (itemTOC) =>
                    (itemTOC = {
                      text: itemTOC,
                      href: blocksData[blocksData.indexOf(itemBlock)].id,
                    })
                );
              }
            });
          });
        }
        const request = {
          shop_id: shop_id,
          id: this.$route.params.id ? this.$route.params.id : "",
          no: this.infoNew.no,
          title: this.infoNew.title,
          description: this.infoNew.description,
          slags_id:
            this.checkPreview == 2 || this.checkAddEditor == 2
              ? this.infoNew.slags_id
              : slags,
          start_access_date: this.infoNew.start_access_date
            ? this.infoNew.start_access_date
            : "",
          end_access_date: this.infoNew.end_access_date
            ? this.infoNew.end_access_date
            : "",
          url_access_redirect: this.infoNew.url_access_redirect
            ? this.infoNew.url_access_redirect
            : "",
          type: this.infoNew.type,
          type_header: this.infoNew.type_header,
          is_login: this.infoNew.is_login,
          is_public: this.infoNew.is_public,
          colorBackground: this.infoNew.colorBackground,
          repeatBackground: this.infoNew.repeatBackground,
          fixedBackground: this.infoNew.fixedBackground,
          placeBackground: this.infoNew.placeBackground,
          colorArticle: this.infoNew.colorArticle,
          repeatArticle: this.infoNew.repeatArticle,
          fixedArticle: this.infoNew.fixedArticle,
          placeArticle: this.infoNew.placeArticle,
          shadow: this.infoNew.shadow,
          colorShadow: this.infoNew.colorShadow,
          css: this.infoNew.css,
          js: this.infoNew.js,
        };
        const reqOptionColumnPage = {
          urlHeaderImg: "",
          flagLogo: this.option1Column.flagLogo,
          fileNameHeader: this.option1Column.fileNameHeader,
          urlBackground: "",
          flagBackground: this.option1Column.flagBackground,
          fileNameBackground: this.option1Column.fileNameBackground,
          urlArticeBackground: "",
          flagArticle: this.option1Column.flagArticle,
          fileNameArtice: this.option1Column.fileNameArtice,
        };
        if (this.checkPreview == 2) {
          localStorage.setItem(Constants.PAGE_PREVIEW, JSON.stringify(request));
          localStorage.setItem(
            Constants.PREVIEW_COLUMN_PAGE,
            JSON.stringify(reqOptionColumnPage)
          );
          // if (this.infoNew.type == Constants.FIXED_PAGE_TYPE["column_page"]) {
          this.$store.commit("set", ["isShowLayout", true]);
          this.$router.push({
            name: this.$route.params.shopId
              ? "previewColumnPage"
              : "previewColumnPage domain",
          });
          // } else {
          //   this.$router.push({
          //     name: this.$route.params.shopId
          //       ? "previewPage"
          //       : "previewPage domain",
          //   });
          // }
        } else if (this.checkAddEditor == 2) {
          let obj = { description: "" };
          if (this.ind != null) {
            this.infoNew.description.splice(this.ind + 1, 0, obj);
            this.checkAdd = this.ind + 1;
            this.isEdit = !this.isEdit;
            var block = document.getElementById("editor-" + (this.ind + 1));
            if (block) {
              block
                .getElementsByClassName("codex-editor")[0]
                .classList.add("codex-editor--empty");
              var child = block.getElementsByClassName("codex-editor")[0];
              if (child) {
                var content = child.getElementsByClassName("ce-block__content");
                if (content.length > 0) {
                  for (var i = 0; i < content.length; i++) {
                    var descript =
                      content[i].getElementsByClassName("ce-paragraph");
                    var descriptFile =
                      content[i].getElementsByClassName("cdx-block");
                    if (descript && descript.length > 0)
                      descript[0].innerHTML = "";
                    if (descriptFile && descriptFile.length > 0)
                      descriptFile[0].innerHTML = "";
                  }
                }
              }
            }
            this.ind = null;
          }
        } else {
          var formData = new FormData();
          formData.append("shop_id", request.shop_id);
          formData.append("id", request.id);
          formData.append("title", request.title);
          formData.append(
            "description",
            JSON.stringify(this.infoNew.description)
          );
          if (request.slags_id && request.slags_id.length > 0) {
            request.slags_id.forEach((item, index) => {
              formData.append("slags_id[" + index + "]", item);
            });
          }
          // formData.append("slags_id", request.slags_id);
          formData.append("start_access_date", request.start_access_date);
          formData.append("end_access_date", request.end_access_date);
          formData.append("url_access_redirect", request.url_access_redirect);
          formData.append("type", request.type);
          formData.append("type_header", request.type_header);
          formData.append("is_login", request.is_login);
          formData.append("is_public", request.is_public);
          formData.append(
            "logo_image",
            this.infoNew.imgHeader ? this.infoNew.imgHeader : ""
          );
          formData.append("flagLogo", reqOptionColumnPage.flagLogo);
          formData.append("background_color", request.colorBackground);
          formData.append(
            "background_image",
            this.infoNew.imgBackground ? this.infoNew.imgBackground : ""
          );
          formData.append("flagBackground", reqOptionColumnPage.flagBackground);
          formData.append("dulipcate_background", request.repeatBackground);
          formData.append("fixed_background", request.fixedBackground);
          formData.append("position_background", request.placeBackground);
          formData.append("article_background_color", request.colorArticle);
          formData.append(
            "article_background_image",
            this.infoNew.imgArticle ? this.infoNew.imgArticle : ""
          );
          formData.append("flagArticle", reqOptionColumnPage.flagArticle);
          formData.append("article_background_repeat", request.repeatArticle);
          formData.append("article_background_fixed", request.fixedArticle);
          formData.append("article_background_position", request.placeArticle);
          formData.append("article_background_shadow", request.shadow);
          formData.append("shadow_color", request.colorShadow);
          formData.append("css", request.css);
          formData.append("js", request.js);
          if (request.id) {
            const dataReturn = await this.$store.dispatch(
              "UpdateFixedPage",
              formData
            );
            if (dataReturn.success) {
              localStorage.removeItem(Constants.PAGE_PREVIEW);
              localStorage.removeItem(Constants.NO_FIXED_PAGE);
              localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
              // this.$router.go(-1);
              this.$router.push({
                name: this.$route.params.shopId
                  ? "listFixedPage"
                  : "listFixedPage domain",
              });
            } else {
              this.check = 0;
              this.loadingSave = false;
            }
          } else {
            const dataReturn = await this.$store.dispatch(
              "CreateFixedPage",
              formData
            );
            if (dataReturn.success) {
              localStorage.removeItem(Constants.PAGE_PREVIEW);
              localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
              // this.$router.go(-1);
              this.$router.push({
                name: this.$route.params.shopId
                  ? "listFixedPage"
                  : "listFixedPage domain",
              });
            } else {
              this.check = 0;
              this.loadingSave = false;
            }
          }
        }
      }
    },
    async onChangeHeaderImage(e) {
      if (e.target.files) {
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
          this.$toasted.error("图片类型必须是.jpeg,jpg,png中的一种");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else if (
          e.target.files[0] &&
          e.target.files[0].size / 1024 / 1024 > 100
        ) {
          this.$toasted.error("画像1枚につき100MBまで");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else {
          this.option1Column.fileNameHeader = e.target.files[0].name;
          this.option1Column.urlHeaderImg = await this.getBase64(
            e.target.files[0]
          );
          this.$store.commit("set", [
            "checkHeaderImg",
            this.option1Column.urlHeaderImg,
          ]);
          this.option1Column.flagLogo = false;
        }
      }
    },
    deleteImg(type) {
      if (type == 1) {
        this.$refs.headerFile.value = null;
        this.option1Column.fileNameHeader = "";
        this.option1Column.urlHeaderImg = "";
        this.$store.commit("set", [
          "checkHeaderImg",
          this.option1Column.urlHeaderImg,
        ]);
        this.option1Column.flagLogo = true;
      } else if (type == 2) {
        this.$refs.backgroundFile.value = null;
        this.option1Column.fileNameBackground = "";
        this.option1Column.urlBackground = "";
        this.$store.commit("set", [
          "checkBackgroundImg",
          this.option1Column.urlBackground,
        ]);
        this.option1Column.flagBackground = true;
      } else {
        this.$refs.articleFile.value = null;
        this.option1Column.fileNameArtice = "";
        this.option1Column.urlArticeBackground = "";
        this.$store.commit("set", [
          "checkArticleImg",
          this.option1Column.urlArticeBackground,
        ]);
        this.option1Column.flagArticle = true;
      }
    },
    async onChangeBackgroundImage(e) {
      if (e.target.files) {
        if (!/\.(jpg|jpeg|png|gif|JPG|PNG|GIF)$/.test(e.target.value)) {
          this.$toasted.error("图片类型必须是.jpeg,jpg,png,gif中的一种");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else if (
          e.target.files[0] &&
          e.target.files[0].size / 1024 / 1024 > 100
        ) {
          this.$toasted.error("画像1枚につき100MBまで");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else {
          this.option1Column.fileNameBackground = e.target.files[0].name;
          this.option1Column.urlBackground = await this.getBase64(
            e.target.files[0]
          );
          this.$store.commit("set", [
            "checkBackgroundImg",
            this.option1Column.urlBackground,
          ]);
          this.option1Column.flagBackground = false;
        }
      }
    },
    async onChangeArticleImage(e) {
      if (e.target.files) {
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
          this.$toasted.error("图片类型必须是.jpeg,jpg,png中的一种");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else if (
          e.target.files[0] &&
          e.target.files[0].size / 1024 / 1024 > 100
        ) {
          this.$toasted.error("画像1枚につき100MBまで");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else {
          this.option1Column.fileNameArtice = e.target.files[0].name;
          this.option1Column.urlArticeBackground = await this.getBase64(
            e.target.files[0]
          );
          this.$store.commit("set", [
            "checkArticleImg",
            this.option1Column.urlArticeBackground,
          ]);
          this.option1Column.flagArticle = false;
        }
      }
    },
    getBase64(file) {
      // Returns a promise which gets resolved or rejected based on the reader events
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        // Sets up even listeners BEFORE you call reader.readAsDataURL
        reader.onload = function () {
          const result = reader.result;
          return resolve(result);
        };
        reader.onerror = function (error) {
          return reject(error);
        };
        // Calls reader function
        reader.readAsDataURL(file);
      });
    },
    setBackgroundRepeat(value) {
      // this.infoNew.indBackgroundRepeat = index;
      this.infoNew.repeatBackground = value;
    },
    setBackgroundFixed(value) {
      this.infoNew.fixedBackground = value;
    },
    setBackgroundPlace(value) {
      this.infoNew.placeBackground = value;
    },
    setArticleRepeat(value) {
      this.infoNew.repeatArticle = value;
    },
    setArticleFixed(value) {
      this.infoNew.fixedArticle = value;
    },
    setArticlePlace(value) {
      this.infoNew.placeArticle = value;
    },
    setArticleShadow(value) {
      this.infoNew.shadow = value;
    },
    checkTab(value) {
      this.typeSetting = value;
      // this.changeStatusEdit();
    },
    showSlagConditionDetail(item, index) {
      this.indexBlock = index;
      const listOptionsContent = this.listContentOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.value]: cur }),
        {}
      );
      const listOptionsSlag = this.listSlagOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.slag_id]: cur }),
        {}
      );
      if (this.infoNew.description[this.indexBlock].content_condition_1) {
        this.content_condition_1 = this.fomatObject(
          this.infoNew.description[this.indexBlock].content_condition_1,
          listOptionsContent
        );
      } else {
        this.content_condition_1 = null;
      }
      if (this.infoNew.description[this.indexBlock].content_condition_2) {
        this.content_condition_2 = this.fomatObject(
          this.infoNew.description[this.indexBlock].content_condition_2,
          listOptionsContent
        );
      } else {
        this.content_condition_2 = null;
      }
      if (this.infoNew.description[this.indexBlock].content_condition_3) {
        this.content_condition_3 = this.fomatObject(
          this.infoNew.description[this.indexBlock].content_condition_3,
          listOptionsContent
        );
      } else {
        this.content_condition_3 = null;
      }
      if (this.infoNew.description[this.indexBlock].content_condition_4) {
        this.content_condition_4 = this.fomatObject(
          this.infoNew.description[this.indexBlock].content_condition_4,
          listOptionsContent
        );
      } else {
        this.content_condition_4 = null;
      }
      if (this.infoNew.description[this.indexBlock].slag_condition_1) {
        this.slag_condition_1 = this.fomatObject(
          this.infoNew.description[this.indexBlock].slag_condition_1,
          listOptionsSlag
        );
      } else {
        this.slag_condition_1 = null;
      }
      if (this.infoNew.description[this.indexBlock].slag_condition_2) {
        this.slag_condition_2 = this.fomatObject(
          this.infoNew.description[this.indexBlock].slag_condition_2,
          listOptionsSlag
        );
      } else {
        this.slag_condition_2 = null;
      }
      if (this.infoNew.description[this.indexBlock].slag_condition_3) {
        this.slag_condition_3 = this.fomatObject(
          this.infoNew.description[this.indexBlock].slag_condition_3,
          listOptionsSlag
        );
      } else {
        this.slag_condition_3 = null;
      }
      if (this.infoNew.description[this.indexBlock].slag_condition_4) {
        this.slag_condition_4 = this.fomatObject(
          this.infoNew.description[this.indexBlock].slag_condition_4,
          listOptionsSlag
        );
      } else {
        this.slag_condition_4 = null;
      }
      this.$bvModal.show("modal-detail-condition");
      this.changeMutiSelectContentCondition();
      this.changeMutiSelectSlagCondition();
    },
    converContentCondition(array) {
      if (array && array.length > 0) {
        const listContentsCondition = [];
        array.map((item) => {
          listContentsCondition.push(item.value);
        });
        return listContentsCondition;
      }
    },
    converSlagCondition(array) {
      if (array && array.length > 0) {
        const listContentsCondition = [];
        array.map((item) => {
          listContentsCondition.push(item.slag_id);
        });
        return listContentsCondition;
      }
    },
    fomatObject(array, object) {
      if (array && array.length > 0) {
        let ArrrayObject = [];
        Object.keys(object)
          .filter((key) => array.includes(parseInt(key)))
          .reduce((obj, key) => {
            ArrrayObject.push(object[key]);
          }, {});
        return ArrrayObject;
      }
    },
    changeMutiSelectContentCondition() {
      if (!this.content_condition_1) {
        this.disabledConditionContent2 = true;
        this.content_condition_2 = null;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContent2 = false;
      }
      if (!this.content_condition_1 || !this.content_condition_2) {
        this.disabledConditionContent3 = true;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContent3 = false;
      }
    },
    changeMutiSelectSlagCondition() {
      if (!this.slag_condition_1) {
        this.disabledConditionSlag2 = true;
        this.slag_condition_2 = null;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlag2 = false;
      }
      if (!this.slag_condition_1 || !this.slag_condition_2) {
        this.disabledConditionSlag3 = true;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlag3 = false;
      }
    },
    updateCondition() {
      this.infoNew.description[this.indexBlock].content_condition_1 =
        this.converContentCondition(this.content_condition_1);
      this.infoNew.description[this.indexBlock].content_condition_2 =
        this.converContentCondition(this.content_condition_2);
      this.infoNew.description[this.indexBlock].content_condition_3 =
        this.converContentCondition(this.content_condition_3);
      this.infoNew.description[this.indexBlock].content_condition_4 =
        this.converContentCondition(this.content_condition_4);
      this.infoNew.description[this.indexBlock].slag_condition_1 =
        this.converSlagCondition(this.slag_condition_1);
      this.infoNew.description[this.indexBlock].slag_condition_2 =
        this.converSlagCondition(this.slag_condition_2);
      this.infoNew.description[this.indexBlock].slag_condition_3 =
        this.converSlagCondition(this.slag_condition_3);
      this.infoNew.description[this.indexBlock].slag_condition_4 =
        this.converSlagCondition(this.slag_condition_4);
      this.$bvModal.hide("modal-detail-condition");
    },
    closeModalCondition() {
      this.$bvModal.hide("modal-detail-condition");
    },
  },
};
</script>
<style lang="scss">
.ql-container {
  border: none !important;
  .ql-editor {
    background-color: white;
    height: auto;
  }
}
.error-input {
  border: solid 1px #e55353 !important;
}
.message-error {
  color: #e55353;
}
.all-description .mt-5:nth-child(1) {
  margin-top: 0 !important;
}
.add-new-description:hover {
  cursor: pointer;
}
.title-btn {
  display: flex;
  margin-top: 20px;
  align-items: center;
  @media (max-width: 767px) {
    display: block;
    button {
      @media (max-width: 767px) {
        display: block;
        margin-top: 10px;
      }
    }
  }
}
.btn-toggle-active {
  display: inline-block;
  position: relative;
  .btn-checkbox-active {
    border: 0;
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    inset: 0;
    &:checked ~ .btn-checkbox-active {
      background: #ff644e;
      color: #fff;
    }
  }
  label {
    min-width: 150px;
  }
}
.btn-toggle-active {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
.btn-tab-sale-active {
  background: #ff644e;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff644e;
}
.div-preview-btn {
  position: absolute;
  right: 0;
}
@media (max-width: 1280px) {
  .div-preview-btn {
    margin-left: 40px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1024px) {
  .btn-tab-sale-inactive {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
  .btn-tab-sale-active {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
}
@media (max-width: 450px) {
  .btn-tab-sale-inactive {
    margin-top: 10px;
  }
  .btn-tab-sale-active {
    margin-top: 10px;
  }
  .btn-return-respon {
    margin-top: 5px;
  }
}
.create-content-main {
  .cdx-search-field__input {
    height: unset !important;
    margin: unset !important;
    width: unset !important;
  }
}
.create-content-main .codex-editor .ce-inline-tool-hyperlink--input {
  margin: 0px 0px 10px 0px;
}
.create-content-main .codex-editor .form-control {
  margin: 0px 0px 10px 0px;
}
</style>
